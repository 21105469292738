"use client";

import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./SetDetail.module.scss";

interface Props {
  bags: 12 | 28 | 46;
  lp?: "gothic" | "maru" | "form";
}

const base = "https://asset.basefood.co.jp/lp/common";
const imageMap = {
  gothic: {
    12: `${base}/0828_set12_gothic.jpg`,
    28: `${base}/0828_set28_gothic.jpg`,
    46: `${base}/0828_set46_gothic.jpg`,
  },
  maru: {
    12: `${base}/0828_set12_maru.jpg`,
    28: `${base}/0828_set28_maru.jpg`,
    46: `${base}/0828_set46_maru.jpg`,
  },
  form: {
    12: `${base}/0805_set12_form.png`,
    28: `${base}/0828_set28_form.png`,
    46: `${base}/0805_set46_form.png`,
  },
};

export function SetDetail({ bags, lp = "gothic" }: Props): React.ReactNode {
  return (
    <Image
      src={imageMap[lp][bags]}
      alt={`${bags}袋セット`}
      aspectRatio="750 / 1349"
      containerClassName={clsx(styles.image)}
    />
  );
}
