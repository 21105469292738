import { useMemo } from "react";

import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { amazonGetCheckout } from "@/generated/axios-functions/payseAPI";
import { AmazonGetCheckout200, AmazonGetCheckout200OneOf } from "@/generated/open-api/schemas";
import { convertAmazonGetCheckoutResToAmazonPayGetCheckoutData } from "@/models/amazonpay/converter";

interface AmazonPayCheckoutParams {
  amazonCheckoutSessionId: string | null; // cancelされた場合はnullを渡す
}

/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function useAmazonGetCheckoutSuspense({ amazonCheckoutSessionId }: AmazonPayCheckoutParams) {
  const { data, error, ...rest } = useSuspenseQuery({
    queryKey: getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId),
    queryFn: async () => {
      if (!amazonCheckoutSessionId) {
        return null;
      }
      return await amazonGetCheckout({ amazonCheckoutSessionId });
    },
  });

  const amazonPayGetCheckoutData = useMemo(() => {
    return isSuccessResponse(data)
      ? convertAmazonGetCheckoutResToAmazonPayGetCheckoutData(data)
      : undefined;
  }, [data]);

  // Errorであっても、200レスポンスが返ってくるため、検知できない
  // そのため、dataがエラーレスポンスかどうかを判定して、エラーの場合は、errorとして扱う。
  const errorRes = useMemo(() => (isErrorResponse(data) ? data : error), [data, error]);

  return { data: amazonPayGetCheckoutData, error: errorRes, ...rest };
}

type PrefetchAmazonPayCheckoutParams = AmazonPayCheckoutParams &
  Parameters<typeof amazonGetCheckout>[1];

export async function prefetchAmazonGetCheckout(
  queryClient: QueryClient,
  params: PrefetchAmazonPayCheckoutParams
) {
  const { amazonCheckoutSessionId, ...rest } = params;
  await queryClient.prefetchQuery({
    queryKey: getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId),
    queryFn: async () => {
      if (!amazonCheckoutSessionId) {
        return null;
      }
      return await amazonGetCheckout({ amazonCheckoutSessionId }, rest);
    },
  });
}

export function getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId: string | null) {
  return [`/amazon/get_checkout`, amazonCheckoutSessionId];
}

function isSuccessResponse(data: AmazonGetCheckout200 | null): data is AmazonGetCheckout200OneOf {
  return (
    !!data &&
    "email" in data &&
    "name" in data &&
    "payment_preferences" in data &&
    "shipping_address" in data
  );
}

function isErrorResponse(data: AmazonGetCheckout200 | null): data is { error: string } {
  return !!data && "error" in data;
}
