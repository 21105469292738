"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";

import { FEATURE_FLAG } from "@/configs/system";

import styles from "./ExtraGiftCampaignBar.module.scss";

// おまけ増量キャンペーンバーを表示するpathを取得
const isExtraGiftCampaignBarPath = (pathname: string): boolean => {
  // カートページのみおまけ増量キャンペーンバーを表示
  if (pathname.startsWith("/cart")) {
    return true;
  }

  // 冷凍食品と冷凍セット以外の商品ページのみおまけ増量キャンペーンバーを表示
  const excludeProductsPaths = [
    "/subscription/4",
    "/deli/basepasta/bolognese",
    "/deli/basepasta/tarako",
    "/deli/basepasta/creamymushroom",
  ];
  if (
    pathname.startsWith("/products") &&
    !excludeProductsPaths.find((path) => pathname.endsWith(path))
  ) {
    return true;
  }

  return false;
};

export function ExtraGiftCampaignBar(): React.ReactNode {
  const pathname = usePathname();
  if (!FEATURE_FLAG.isExtraGiftCampaignActive || !isExtraGiftCampaignBarPath(pathname)) {
    return null;
  }

  return (
    <p
      className={clsx(
        "text__s text__bold text__center pd__top__s pd__bottom__s",
        styles.extraGiftCampaignBg
      )}
    >
      今ならおまけ3袋に増量中！
    </p>
  );
}
