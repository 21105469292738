import { type HTMLProps } from "react";

import clsx from "clsx";

import styles from "./Hr.module.scss";

export interface HrProps extends HTMLProps<HTMLHRElement> {
  noMargin?: boolean;
}

export function Hr({ className, noMargin, ...restProps }: HrProps): React.ReactNode {
  return <hr {...restProps} className={clsx(styles.hr, noMargin && styles.noMargin, className)} />;
}
