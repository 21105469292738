export const PaymentMethod = {
  credit: "credit",
  konbini: "konbini",
  ginkou: "ginkou",
  daibiki: "daibiki",
  amazon: "amazon",
  pointOnly: "pointOnly",
} as const;
export type PaymentMethod = keyof typeof PaymentMethod;

export const paymentMethodLabels: Record<PaymentMethod, string> = {
  [PaymentMethod.credit]: "クレジットカード支払い",
  [PaymentMethod.konbini]: "コンビニ支払い",
  [PaymentMethod.ginkou]: "銀行振込",
  [PaymentMethod.daibiki]: "代金引換",
  [PaymentMethod.amazon]: "Amazon Pay",
  [PaymentMethod.pointOnly]: "ポイントのみ",
};
