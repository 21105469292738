import { format, isValid, parse } from "date-fns";
import { DeepPartial } from "react-hook-form";

import { CustomerModel } from "@/models/customer/type";
import { DeliveryReceiveOption } from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { NuxtStoreCart, NuxtCommonAddress, NuxtCommonPaymentData } from "@/storage/types";
import { NuxtStoreCartForm } from "@/storage/types/NuxtStoreCartForm";
import { objectMerge } from "@/utils/object";
import { loadStripe } from "@/utils/stripe";

import {
  AddressValues,
  CheckoutFormValues,
  DeliveryOptionsValues,
  PaymentDataValues,
  UserCredentialsValues,
} from "./schema";
import { UseAmazonPayReturn } from "../../amazon/useAmazonPay";

export const convertAddressStorageToForm = (storageAddress: Partial<NuxtCommonAddress>) => {
  return {
    firstName: storageAddress.first_name ?? "",
    lastName: storageAddress.last_name ?? "",
    zip: storageAddress.zip ?? "",
    province: storageAddress.province ?? "",
    city: storageAddress.city ?? "",
    addressLine1: storageAddress.address_line1 ?? "",
    addressLine2: storageAddress.address_line2 ?? "",
    phone: storageAddress.phone ?? "",
  };
};

export const convertAddressFormToStorage = (address: Partial<AddressValues>) => {
  return {
    first_name: address.firstName ?? "",
    last_name: address.lastName ?? "",
    zip: address.zip ?? "",
    province: address.province ?? "",
    converted_province: address.province ?? "",
    city: address.city ?? "",
    address_line1: address.addressLine1 ?? "",
    address_line2: address.addressLine2 ?? "",
    phone: address.phone ?? "",
  };
};

export const convertPaymentDataStorageToForm = (storageForm: NuxtStoreCart["form"]) => {
  return {
    paymentMethod: storageForm?.payment_method ?? PaymentMethod.credit,
    creditCard: undefined,
  };
};

export const convertPaymentDataFormToStorage = (formPaymentData: Partial<PaymentDataValues>) => {
  return {
    payment_method: formPaymentData.paymentMethod ?? undefined,
    payment_data: {
      amazon_checkout_session_id: formPaymentData.amazonCheckoutSessionId,
    } as NuxtCommonPaymentData,
  };
};

export const convertDeliveryOptionsStorageToForm = (storageForm: NuxtStoreCart["form"]) => {
  const deliveryDate = parse(storageForm.delivery_date ?? "", "yyyy/MM/dd", new Date());
  const isValidDate = isValid(deliveryDate);
  return {
    isSpecifiedDate: isValidDate,
    deliveryDate: isValidDate ? deliveryDate : undefined,
    deliveryTimezone: storageForm?.delivery_timezone,
    deliveryReceiveOption: storageForm?.delivery_location_code ?? DeliveryReceiveOption.FaceToFace,
  } as DeliveryOptionsValues;
};

export const convertDeliveryOptionsFormToStorage = (
  formDeliveryOptions: Partial<DeliveryOptionsValues>
) => {
  return {
    delivery_date: formDeliveryOptions.deliveryDate
      ? format(formDeliveryOptions.deliveryDate, "yyyy/MM/dd") // 'format' from date-fns or similar library
      : "free",
    delivery_timezone: formDeliveryOptions.deliveryTimezone ?? "free",
    delivery_location_code:
      formDeliveryOptions.deliveryReceiveOption ?? DeliveryReceiveOption.FaceToFace,
  };
};

interface GetDefaultFormValuesProps {
  localStorageForm?: NuxtStoreCart["form"] | null;
  sessionStorageForm: NuxtStoreCart["form"] | null;
  customer: CustomerModel | null; // ログインしていない場合はnull
  amazonPayData: UseAmazonPayReturn;
}

export const getDefaultFormValues = ({
  localStorageForm,
  sessionStorageForm,
  customer, // ログインしていない場合はnull
  amazonPayData,
}: GetDefaultFormValuesProps): DeepPartial<CheckoutFormValues> => {
  const { amazonPayCheckoutData, amazonPayEnable, amazonPayCheckoutSessionId } = amazonPayData;
  let shippingAddress: DeepPartial<AddressValues> = {};
  if (amazonPayEnable) {
    shippingAddress = {
      ...amazonPayCheckoutData.shippingAddress,
      firstName: amazonPayCheckoutData.shippingAddress.firstName ?? "",
    };
  } else {
    const localStorageAddress = localStorageForm?.shipping_address;
    const formAddress = localStorageAddress ? convertAddressStorageToForm(localStorageAddress) : {};
    const customerAddress = { ...(customer?.address ?? {}) };
    shippingAddress = objectMerge(formAddress, customerAddress);
  }

  const credentials: DeepPartial<UserCredentialsValues> = {
    email: customer?.email ?? amazonPayCheckoutData?.email ?? localStorageForm?.email ?? "",
    password: localStorageForm?.password ?? "",
    acceptsMarketing: true,
  };

  let billingAddress: DeepPartial<AddressValues> = {};

  if (amazonPayCheckoutData) {
    const sessionStorageBillingAddress = sessionStorageForm?.different_billing_address;
    if (sessionStorageBillingAddress) {
      billingAddress = convertAddressStorageToForm(sessionStorageBillingAddress);
    }
  } else if (localStorageForm?.different_billing_address) {
    const localStorageBillingAddress = localStorageForm?.different_billing_address;
    billingAddress = convertAddressStorageToForm(localStorageBillingAddress);
  }

  let billingAddressSelect = false;
  if (amazonPayCheckoutData) {
    billingAddressSelect = sessionStorageForm?.different_billing_address_select ?? false;
  } else {
    billingAddressSelect = localStorageForm?.different_billing_address_select ?? false;
  }

  let paymentData: DeepPartial<PaymentDataValues> = {};
  if (amazonPayEnable) {
    paymentData = {
      paymentMethod: PaymentMethod.amazon,
      amazonCheckoutSessionId: amazonPayCheckoutSessionId,
    };
  } else {
    if (localStorageForm) {
      paymentData = convertPaymentDataStorageToForm(localStorageForm);
    }
  }

  let deliveryOptions: DeepPartial<DeliveryOptionsValues> = {};

  if (amazonPayCheckoutData && sessionStorageForm) {
    // AmazonPayが有効な場合のみSessionStorageから取得
    deliveryOptions = convertDeliveryOptionsStorageToForm(sessionStorageForm);
  } else {
    if (localStorageForm) {
      deliveryOptions = convertDeliveryOptionsStorageToForm(localStorageForm);
    }
  }

  return {
    credentials,
    paymentData,
    shippingAddress,
    billingAddress,
    billingAddressSelect,
    deliveryOptions,
  };
};

export const convertFormValuesToStorage = (formValues: DeepPartial<CheckoutFormValues>) => {
  const {
    credentials,
    shippingAddress,
    billingAddress,
    billingAddressSelect,
    deliveryOptions,
    paymentData,
  } = formValues;

  const storageForm = {
    email: credentials?.email ?? "",
    password: credentials?.password ?? "",
    accepts_marketing: credentials?.acceptsMarketing ?? true,
    shipping_address: convertAddressFormToStorage(shippingAddress ?? {}),
    different_billing_address: billingAddress
      ? convertAddressFormToStorage(billingAddress)
      : undefined,
    different_billing_address_select: billingAddressSelect,
    ...convertDeliveryOptionsFormToStorage(deliveryOptions ?? {}),
    ...convertPaymentDataFormToStorage((paymentData ?? {}) as Partial<PaymentDataValues>),
  };
  return storageForm;
};

/**
 * コンビニ支払い、銀行振込の場合のStripeの支払いを確定する
 */
export const confirmStripePayment = async (secret: string, formValues: NuxtStoreCartForm) => {
  if (formValues.payment_method === "konbini") {
    const stripe = await loadStripe();
    await stripe.confirmKonbiniPayment(
      secret,
      {
        payment_method: {
          billing_details: { name: "**** ****", email: formValues.email! },
        },
        payment_method_options: {
          konbini: {
            confirmation_number: formValues.shipping_address!.phone,
          },
        },
      },
      {
        // false: コンビニ支払手順のダイアログを非表示
        handleActions: false,
      }
    );
  } else if (formValues.payment_method === "ginkou") {
    const stripe = await loadStripe();
    await stripe.confirmCustomerBalancePayment(
      secret,
      { payment_method: { customer_balance: {} } },
      {
        // false: 銀行支払手順を非表示
        handleActions: false,
      }
    );
  }
};
