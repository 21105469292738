import { type RecommendSet } from "./types";

export const FORM_DEFAULT_SELECTED: RecommendSet[] = [
  {
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 4 },
      { sku: "102010", quantity: 4 },
      { sku: "102016", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102011", quantity: 4 },
    ],
    subscription: true,
  },
];
