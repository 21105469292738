import { type RecommendSet } from "./types";

export const LP_FORM_01: RecommendSet[] = [
  {
    name: "定番のパンをおためし 12袋セット",
    products: [
      { sku: "102008", quantity: 2 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102011", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/0805_set12_form.png",
        aspectRatio: "164/135",
      },
    ],
    sub_title: "定番のパンをおためし",
  },
  {
    name: "からだと向き合いたいなら 28袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 4 },
      { sku: "102010", quantity: 4 },
      { sku: "102016", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102011", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/0828_set28_form.png",
        aspectRatio: "164/135",
      },
    ],
    sub_title: "からだと向き合いたいなら",
  },
  {
    name: "家族の健康習慣のために 46袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102016", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102011", quantity: 6 },
      { sku: "102014", quantity: 4 },
      { sku: "102007", quantity: 4 },
      { sku: "102012", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/0805_set46_form.png",
        aspectRatio: "559/701",
      },
    ],
    sub_title: "家族の健康習慣のために",
  },
];
