import { type RecommendSet } from "./types";

export const START_SET: RecommendSet[] = [
  {
    name: "ベースブレッド12袋セット",
    short_name: "パン 12袋",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102011", quantity: 2 },
    ],
    subscription: true,
  },
  {
    name: "ベースブレッド10袋＆ベースクッキー10袋セット",
    short_name: "パン＆クッキー 20袋",
    products: [
      { sku: "102008", quantity: 2 },
      { sku: "102009", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "104003", quantity: 2 },
      { sku: "104004", quantity: 2 },
      { sku: "104006", quantity: 2 },
      { sku: "104008", quantity: 2 },
      { sku: "104009", quantity: 2 },
    ],
    subscription: true,
  },
  {
    name: "ベースブレッド10袋&ベースパスタ4袋セット",
    short_name: "パン＆パスタ 14袋",
    products: [
      { sku: "102008", quantity: 2 },
      { sku: "102009", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "101001", quantity: 2 },
      { sku: "101002", quantity: 2 },
    ],
    subscription: true,
  },
  {
    name: "冷凍パスタ6袋セット",
    short_name: "冷凍パスタ 6袋",
    products: [
      { sku: "111001", quantity: 2 },
      { sku: "111002", quantity: 2 },
      { sku: "111003", quantity: 2 },
    ],
    subscription: true,
  },
  {
    name: "ベースブレッド16袋&ベースパンケーキミックス2袋セット",
    short_name: "パン＆パンケーキミックス 18袋",
    products: [
      { sku: "102008", quantity: 2 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102012", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102014", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "105001", quantity: 2 },
    ],
    subscription: true,
  },
];
