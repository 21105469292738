import { usePathname } from "next/navigation";

const MyPageRootPath = "/mypage";

const PathNameMap: Record<string, string> = {
  "/mypage": "マイページ",
  "/mypage/address": "お客様情報",
  "/mypage/subscription": "継続コース管理",
  "/mypage/freeze_subscription": "継続コース管理",
  "/mypage/purchase_history": "注文・ポイント履歴",
  "/mypage/purchase_history/order_detail": "注文詳細",
  "/mypage/purchase_history/point_detail": "ポイント詳細",
  "/mypage/mile": "マイル詳細",
  "/mypage/mile/exchange": "マイル・ギフト交換",
};

function removeLastTrailingSlashPath(pathname: string) {
  return pathname.split("/").slice(0, -1).join("/");
}

/**
 * 後ろからpathを１つづつを消して、PathNameMapに存在するものを返す。
 * 例：
 * - /mypage/purchase_history/point_detail  -> /mypage/purchase_history/point_detail
 * - /mypage/purchase_history/point_detail/1  -> /mypage/purchase_history/point_detail
 * - /mypage/purchase_history/point_detail?id=1  -> /mypage/purchase_history
 *
 *
 * @param pathname
 */
function getPathNameInPathNameMap(pathname: string): string | undefined {
  if (pathname in PathNameMap) {
    return pathname;
  }

  if (pathname === "") {
    return;
  }

  return getPathNameInPathNameMap(removeLastTrailingSlashPath(pathname));
}

function generateBreadcrumbItems(pathname: string) {
  const paths = pathname.split("/").filter(Boolean);
  const items = paths.map((_, index) => {
    const pathKey = `/${paths.slice(0, index + 1).join("/")}`;
    return {
      path: pathKey,
      title: PathNameMap[pathKey],
    };
  });

  return [{ path: "/", title: "HOME" }, ...items];
}

export function useMyPagePathInfo() {
  const originalPathname = usePathname();
  const pathname = getPathNameInPathNameMap(originalPathname);

  if (!pathname) {
    throw new Error("Invalid pathname");
  }

  // 親pathを取得
  const parentPath = removeLastTrailingSlashPath(pathname);

  return {
    isRootPage: pathname === MyPageRootPath,
    parentPath,
    parentPathName: PathNameMap[parentPath],
    currentPath: pathname,
    currentPathName: PathNameMap[pathname],
    breadcrumbItems: generateBreadcrumbItems(parentPath),
  };
}
