import NextImage from "next/image";

import { Row } from "@/components";

import familymartImage from "$/images/convenience-store/familymart.png";
import lawsonImage from "$/images/convenience-store/lawson.png";
import ministopImage from "$/images/convenience-store/ministop.png";
import seicomartImage from "$/images/convenience-store/seicomart.png";

import styles from "./ConvenienceStoreImages.module.scss";

const images = [
  {
    image: familymartImage,
    alt: "ファミリーマート",
    height: 14,
  },
  {
    image: lawsonImage,
    alt: "ローソン",
    height: 9,
  },
  {
    image: ministopImage,
    alt: "ミニストップ",
    height: 14,
  },
  {
    image: seicomartImage,
    alt: "セイコーマート",
    height: 11,
  },
];

export function ConvenienceStoreImages() {
  return (
    <Row className={styles.root}>
      {images.map((image) => (
        <NextImage key={image.alt} src={image.image} alt={image.alt} height={image.height} />
      ))}
    </Row>
  );
}
